<template>
    <div class="content">
        <div class="el-row">
            <el-input placeholder="请输入订单号" class="inputBox mg-r-20" v-model="searchObj.orderNumber"></el-input>
            <el-input placeholder="请输入推销员手机" class="inputBox mg-r-20" v-model="searchObj.phone"></el-input>
            <el-select
                    v-model="searchObj.storeIds"
                    multiple
                    placeholder="请选择门店"
                    class="inputBox mg-r-20">
                <el-option
                        v-for="item in storeOptions"
                        :key="item.id"
                        :label="item.storeName"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker
                    class="mg-r-20"
                    v-model="searchObj.date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button type="primary" @click="getOrdersByPage">查询</el-button>
            <el-button type="default" @click="resetSearch">重置</el-button>
            <el-button type="success" @click="exportExcel">导出</el-button>
        </div>
        <div class="el-row mg-t-20">
            <el-table
                    :data="tableData.list"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="name"
                        label="推销员姓名"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="推销员电话">
                </el-table-column>
                <el-table-column
                    prop="salesmanSourceName"
                    label="推销员来源">
                </el-table-column>
                <el-table-column
                    prop="orderNumber"
                    label="订单号">
                </el-table-column>
                <el-table-column
                        prop="telephone"
                        label="客户手机">
                </el-table-column>
                <el-table-column
                        prop="storeName"
                        label="下单门店">
                </el-table-column>
                <el-table-column
                        prop="goodsName"
                        label="商品名">
                </el-table-column>
                <el-table-column
                        prop="goodsNum"
                        label="商品数量">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        prop="actualPay"
                        label="实际支付">
                </el-table-column>
                <el-table-column
                        prop="freightPrice"
                        label="邮费">
                </el-table-column>
                <el-table-column
                        prop="hgoCouponReduce"
                        label="惠购券">
                </el-table-column>
                <el-table-column
                        prop="couponAmount"
                        label="优惠券">
                </el-table-column>
            </el-table>
        </div>
        <!--翻页插件-->
        <div class="el-row mg-t-20">
            <el-pagination
                    align="center"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="tableData.pageNum"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="tableData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { getInternalPurchaseOrders } from "@/api/maketing/internal_purchase";
    import { getStoreList } from "@/api/online_department_store/commodity_manage";
    export default {
        name: "internal_orders",
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                searchObj: {
                    orderNumber: null,
                    phone: null,
                    storeIds: null,
                    date: null
                },
                storeOptions: [],
                tableData: {
                    list: [],
                    pageSize: 10
                },
            }
        },
        mounted() {
            this.resetSearch();
            this.getStores();
        },
        methods: {
            //查询初始化
            initialTable() {
                let param = {
                    orderNumber: null,
                    phone: null,
                    sids: null,
                    date: null,
                    pageSize: this.tableData.pageSize,
                    pageNum: 1
                }
                getInternalPurchaseOrders(param).then(res => {
                    this.tableData = res.data.body
                })
            },
            //每页显示行数
            handleSizeChange(val) {
                this.tableData.pageSize = val
                this.getOrdersByPage()
            },
            //翻页
            handleCurrentChange(val) {
                this.tableData.pageNum = val
                this.getOrdersByPage()
            },
            //条件查询
            getOrdersByPage() {
                let param = {
                    orderNumber: this.searchObj.orderNumber,
                    phone: this.searchObj.phone,
                    sids: this.searchObj.storeIds,
                    date: this.searchObj.date,
                    pageNum: this.tableData.pageNum,
                    pageSize: this.tableData.pageSize
                }
                getInternalPurchaseOrders(param).then(res => {
                    this.tableData = res.data.body
                })
            },
            //重置查询
            resetSearch() {
                this.searchObj.orderNumber = null
                this.searchObj.phone = null
                this.searchObj.storeIds = null
                this.searchObj.date = null
                this.initialTable()
            },
            //查询门店
            getStores() {
                getStoreList().then((res) => {
                    this.storeOptions = res.data.body.list;
                });
            },
            //导出订单
            exportExcel() {
                let param = {
                    orderNumber: this.searchObj.orderNumber,
                    phone: this.searchObj.phone,
                    sids: this.searchObj.storeIds,
                    date: this.searchObj.date
                }
                let url = '/order-service/internalPurchaseOrder/backstageExport'
                this.downFile(url, '内购订单', param, 'post')
            }
        }
    }
</script>

<style scoped>
    .content {
        padding: 20px
    }

    .inputBox {
        width: 178px;
    }

    .mg-r-20 {
        margin-right: 20px;
    }

    .mg-t-20 {
        margin-top: 20px;
    }
</style>
